<template>
  <div>
    <v-app-bar color="primary" dark shrink-on-scroll prominent app>
      <div class="d-flex flex-row align-self-center">
        <v-img src="../assets/img/home/sportinmarl_logo-1.png" max-width="250"></v-img>
      </div>
      <div class="d-none d-sm-none d-md-none d-lg-flex d-xl-flex flex-grow-1 align-self-center justify-center">
        <v-btn text x-large color="white" class="ml-4" exact-active-class="secondary--text" :to="{ name: 'home' }"
          >Suche
        </v-btn>
        <v-btn text x-large color="white" class="ml-4" exact-active-class="secondary--text" :to="{ name: 'kurse' }"
          >Sportkurse und Freizeitangebote
        </v-btn>
        <v-btn
          v-if="!$store.getters.isLoggedIn"
          text
          x-large
          color="white"
          class="ml-4"
          exact-active-class="secondary--text"
          :to="{ name: 'login' }"
          >Vereinslogin</v-btn
        >
        <v-btn
          v-if="$store.getters.isLoggedIn"
          text
          x-large
          color="white"
          class="ml-4"
          exact-active-class="secondary--text"
          :to="{ name: 'admin' }"
          >Admin</v-btn
        >
      </div>
      <div class="d-flex d-lg-none d-xl-none flex-grow-1 align-self-center justify-end">
        <v-app-bar-nav-icon x-large @click="showDrawer = true"></v-app-bar-nav-icon>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="showDrawer" dark color="primary" fixed bottom temporary>
      <v-list nav dense>
        <div class="d-flex flex-column flex-grow-1 align-self-center justify-center">
          <v-btn
            text
            color="white"
            class="mt-4"
            exact-active-class="secondary--text"
            v-for="(route, i) in routes"
            :key="i"
            :to="{ name: route.name }"
            ><v-icon left>{{ route.icon }}</v-icon
            >{{ route.text }}</v-btn
          >
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  data: () => ({
    showDrawer: false,
    routes: [
      { text: 'Suche', name: 'home', icon: 'mdi-home' },
      { text: 'Login', name: 'login', icon: 'mdi-login' },
      { text: 'Admin', name: 'admin', icon: 'mdi-login' },
    ],
    urls: [
      {
        text: 'Sportarten & Vereine',
        url: 'https://www.sport-in-marl.de/sportarten-vereine/',
      },
      { text: 'Integration', url: 'https://www.sport-in-marl.de/integration/' },
      { text: 'Über uns', url: 'https://www.sport-in-marl.de/ueber-uns/' },
      { text: 'Kontakt', url: 'https://www.sport-in-marl.de/kontakt/' },
      { text: 'Deutsch', url: '#' },
    ],
    banners: [
      // eslint-disable-next-line global-require
      require('../assets/img/home/banner.jpg'),
      // eslint-disable-next-line global-require
      require('../assets/img/home/banner2.jpg'),
      // eslint-disable-next-line global-require
      require('../assets/img/home/banner3.jpg'),
      // eslint-disable-next-line global-require
      require('../assets/img/home/banner4.jpg'),
      // eslint-disable-next-line global-require
      require('../assets/img/home/banner5.jpg'),
      // eslint-disable-next-line global-require
      require('../assets/img/home/banner6.jpg'),
    ],
  }),

  computed: {
    GetCurrentBanner() {
      // eslint-disable-next-line global-require
      return require('../assets/img/home/banner.jpg');
    },
  },
};
</script>
