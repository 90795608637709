import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VuexPersist from 'vuex-persist';
// eslint-disable-next-line import/no-cycle
import router from '../router';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
});

const getDefaultState = () => null;

// initial state

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: null,
    current_verein: {
      verein_id: null,
      abteilung_id: null,
    },
    current_verein_data: null,
    current_verein_funktionaere: {
      data: [],
      headers: [
        { text: 'Anrede', value: 'anrede', width: 200 },
        { text: 'Titel', value: 'title', width: 200 },
        { text: 'Vorname', value: 'vorname', width: 200 },
        { text: 'Name', value: 'name', width: 200 },
        { text: 'Funktion', value: 'funktion_name', width: 200 },
        { text: 'Bemerkung', value: 'zusatz_1', width: 200 },
        { text: 'Anschrift', value: 'street', width: 250 },
        { text: 'PLZ', value: 'plz', width: 100 },
        { text: 'Stadt', value: 'city', width: 125 },
        { text: 'E-Mail', value: 'mail', width: 200 },
        { text: 'Telefon', value: 'phone', width: 200 },
        { text: 'Vereinsbeitritt', value: 'vereinsbeitritt', width: 175 },
        { text: 'Geburtstag', value: 'geburtstag', width: 125 },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 100,
        },
      ],
    },
    current_verein_sportarten: {
      abteilung_name: '',
      data: [],
      abteilung_data: [],
      headers: [
        { text: 'Sportart', value: 'sportart_name', width: 300 },
        { text: 'Wiederherstellungs E-Mail Adresse', value: 'recovery_mail', width: 300 },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 100,
        },
      ],
    },
    data_tables: {
      funktionaere: {
        data: [],
        headers: [
          { text: 'Funktion', value: 'funktion', width: 400 },
          { text: 'Priorität', value: 'priority', width: 50 },
        ],
        search: '',
      },
      vereine: {
        data: [],
        headers: [
          { text: 'VereinID', value: 'verein_id', width: 100 },
          { text: 'Vereinsname', value: 'verein_name', width: 300 },
          { text: 'Anschrift', value: 'street', width: 250 },
          { text: 'PLZ', value: 'plz', width: 100 },
          { text: 'Stadt', value: 'city', width: 100 },
          { text: 'Homepage', value: 'homepage_url', width: 150 },
          { text: 'Führungszeugnis liegt vor', value: 'is_fuehrungszeugnis', width: 100 },
          { text: 'Teilnahme an MARLeFIT', value: 'is_marlefit', width: 100 },
        ],
        search: '',
      },
      abteilungen: {
        data: [],
        headers: [
          { text: 'Abteilung', value: 'abteilung_name', width: 300 },
          { text: 'Wiederherstellungs E-Mail Adresse', value: 'recovery_mail', width: 300 },
        ],
        search: '',
      },
      sportstaetten: {
        data: [],
        headers: [
          { text: 'Name', value: 'sportstaette_name', width: 100 },
          { text: 'Anschrift', value: 'street', width: 250 },
          { text: 'PLZ', value: 'plz', width: 100 },
          { text: 'Stadt', value: 'city', width: 200 },
          { text: 'Stadtteil', value: 'stadtteil', width: 200 },
          { text: 'Sportstättentyp', value: 'hallentyp', width: 100 },
          { text: 'Lehrschwimmbecken', value: 'is_lehrschwimmbecken', width: 100 },
          { text: 'Weitere Sportmöglichkeiten', value: 'zusatz_1', width: 200 },
        ],
        search: '',
      },
      sportarten: {
        data: [],
        headers: [
          { text: 'Sportart', value: 'sportart_name', width: 400 },
          { text: 'Piktogramm', value: 'img_url', width: 100 },
        ],
        search: '',
      },
      trainer: {
        data: [],
        headers: [
          { text: 'Name', value: 'trainer_name', width: 200 },
          { text: 'E-Mail', value: 'mail', width: 200 },
          { text: 'Telefon', value: 'phone', width: 200 },
          { text: 'Öffentlich', value: 'is_public', width: 200 },
        ],
        search: '',
      },
      mannschaften: {
        data: [],
        headers: [
          { text: 'Sportart', value: 'sportart_name', width: 200 },
          { text: 'Trainingsgruppe/Mannschaft', value: 'mannschaft_name', width: 200 },
          { text: 'Geschlecht', value: 'geschlecht', width: 150 },
          { text: 'Trainer/in', value: 'trainer_name', width: 150 },
          { text: 'Leistungssport', value: 'is_leistungssport', width: 150 },
          { text: 'Breitensport', value: 'is_hobby', width: 150 },
          { text: 'Jugendsport', value: 'is_jugendsport', width: 150 },
          { text: 'Alter von:', value: 'alter_von', width: 125 },
          { text: 'Alter bis:', value: 'alter_bis', width: 125 },
          { text: 'Standardsportstätte', value: 'default_sportstaette_name', width: 250 },
        ],
        search: '',
      },
      trainings: {
        data: [],
        headers: [
          { text: 'Trainingsgruppe/Mannschaft', value: 'mannschaft_name', width: 200 },
          { text: 'Trainer/in', value: 'trainer_name', width: 200 },
          { text: 'Trainingsbeschreibung', value: 'description', width: 400 },
          { text: 'Montag', value: 'monday', width: 200 },
          { text: 'Dienstag', value: 'tuesday', width: 200 },
          { text: 'Mittwoch', value: 'wednesday', width: 200 },
          { text: 'Donnerstag', value: 'thursday', width: 200 },
          { text: 'Freitag', value: 'friday', width: 200 },
          { text: 'Samstag', value: 'saturday', width: 200 },
          { text: 'Sonntag', value: 'sunday', width: 200 },
        ],
        search: '',
      },
      veranstaltungen: {
        data: [],
        headers: [
          { text: 'Veranstaltung', value: 'title', width: 300 },
          { text: 'Veranstaltungsort', value: 'sportstaette_name', width: 300 },
          { text: 'Vorschau/Flyer', value: 'img_url', width: 200 },
          { text: 'Beschreibung', value: 'description', width: 400 },
          { text: 'Datum', value: 'event_date', width: 200 },
          { text: 'Beginn', value: 'time_start', width: 150 },
          { text: 'Ende', value: 'time_end', width: 150 },
        ],
        search: '',
      },
    },
  },
  is_loading: false,
  mutations: {
    set_pwd_change_required_to_false(state) {
      state.user.pwd_change_required = '0';
    },
    set_current_verein(state, verein) {
      state.current_verein = verein;
    },
    set_current_abteilung(state, abteilung) {
      state.current_verein.abteilung_id = abteilung.abteilung_id;
    },
    auth_request(state) {
      state.status = 'loading';
      state.is_loading = true;
    },
    auth_success(state, serverResponse) {
      state.status = 'success';
      state.is_loading = false;
      state.token = serverResponse.token;
      state.user = serverResponse.user;
      if (state.user.is_admin === '1') {
        state.user.is_admin = true;
      } else {
        state.user.is_admin = false;
      }
      if (state.user.is_verein === '1') {
        state.user.is_verein = true;
      } else {
        state.user.is_verein = false;
      }
      if (state.user.is_abteilung === '1') {
        state.user.is_abteilung = true;
      } else {
        state.user.is_abteilung = false;
      }
      state.current_verein.verein_id = serverResponse.user.verein_id;
      state.current_verein.abteilung_id = serverResponse.user.abteilung_id;
    },
    auth_error(state) {
      state.status = 'error';
      state.token = '';
      state.user = '';
      state.is_loading = false;
    },
    logout(state) {
      state.token = '';
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    SetCurrentVerein({ commit }, verein) {
      commit('set_current_verein', verein);
    },
    SetCurrentAbteilung({ commit }, abteilung) {
      commit('set_current_abteilung', abteilung);
    },
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        axios({
          url: `${process.env.VUE_APP_APIBASEURL}/login.php`,
          data: user,
          method: 'POST',
        })
          .then((resp) => {
            const serverResponse = {
              token: resp.data.token,
              user: resp.data.user,
            };
            localStorage.setItem('token', serverResponse.token);
            axios.defaults.headers.common.Authorization = serverResponse.token;
            commit('auth_success', serverResponse);
            // this.state.user = serverResponse.user;
            // this.state.token = serverResponse.token;
            resolve(resp);
          })
          .catch((err) => {
            commit('auth_error');
            localStorage.removeItem('token');
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout');
        localStorage.removeItem('token');
        delete axios.defaults.headers.common.Authorization;
        resolve();
      });
    },
    validate_token({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_APIBASEURL}/validate_token.php`,
          data: {
            jwt: localStorage.getItem('token') || '',
          },
          method: 'POST',
        })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            localStorage.removeItem('token');
            commit('auth_error');
            reject(err);
            router.push('/login');
          });
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    pwd_change_required: (state) => state.user.pwd_change_required,
  },
  plugins: [vuexLocalStorage.plugin],
});
