<template>
  <v-hover v-slot:default="{ hover }">
    <v-card class="mx-auto" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }">
      <v-sheet color="primary" :height="SheetHeightOnMobile"
        ><v-card-title class="white--text">
          {{ data.verein_name }}
        </v-card-title></v-sheet
      >
      <v-card class="mx-auto" flat max-width="100%">
        <v-card-text>
          <v-row>
            <v-col cols="12"
              ><h3 class="headline">
                {{ data.title }}
              </h3></v-col
            >
          </v-row>
          <v-row>
            <v-col cols="12">
              {{ data.abteilung_name }}
            </v-col></v-row
          >
          <v-row>
            <v-col cols="12">
              Datum: {{ data.event_date }}, {{ data.time_start }} - {{ data.time_end }} Uhr
            </v-col></v-row
          >
          <v-row>
            <v-col cols="12"
              ><a :href="data.sportstaette_url" target="blank">{{ data.sportstaette_name }}</a></v-col
            ></v-row
          >
          <v-row>
            <v-col cols="12"> {{ data.description }} </v-col></v-row
          >
          <v-row v-if="data.img_url">
            <v-col cols="12"><v-img :src="data.img_url" contain></v-img></v-col
          ></v-row>
        </v-card-text>
      </v-card>
    </v-card>
  </v-hover>
</template>

<script>
import VueScreenSize from 'vue-screen-size';

export default {
  name: 'Veranstaltung',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  props: {
    show_details: {
      type: Boolean,
      default: false,
    },
    data: {
      verein_name: {
        type: String,
        required: true,
      },
      abteilung_name: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
      img_url: {
        type: String,
        required: true,
      },
      sportstaette_url: {
        type: String,
        required: true,
      },
      event_date: {
        type: String,
        required: true,
      },
      time_start: {
        type: String,
        required: true,
      },
      time_end: {
        type: String,
        required: true,
      },
    },
  },
  data: () => ({}),
  computed: {
    IsMobileView() {
      if (this.$vssWidth < 800) {
        return true;
      }
      return false;
    },
    SheetHeightOnMobile() {
      if (this.$vssWidth < 800) {
        return 85;
      }
      return 65;
    },
  },
};
</script>

<style></style>
