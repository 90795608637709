<template>
  <v-card>
    <v-card-title>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Suchen" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table :headers="this.table_data.headers" :items="this.table_data.items" :search="search">
      <template v-slot:item.mail="{ item }">
        <a v-if="item.mail" :href="`mailto:${item.mail}`" target="blank">{{ item.mail }}</a>
      </template>
      <template v-slot:item.trainer_mail="{ item }">
        <a v-if="item.trainer_mail && item.tg_voll === '0'" :href="`mailto:${item.trainer_mail}`" target="blank">{{
          item.trainer_mail
        }}</a>
        <a v-else class="text-decoration-line-through"></a>
      </template>
      <template v-slot:item.trainer_phone="{ item }">
        <div v-if="item.trainer_phone && item.tg_voll === '0'">{{ item.trainer_mail }}</div>
        <a v-else class="text-decoration-line-through"></a>
      </template>
      <template v-slot:item.trainer_name="{ item }">
        <a v-if="item.trainer_name && item.tg_voll === '0'" :href="`mailto:${item.trainer_mail}`" target="blank">{{
          item.trainer_mail
        }}</a>
        <a v-else class="font-weight-bold red--text">Angebot aktuell nicht verfügbar</a>
      </template>
      <template v-slot:item.homepage_url="{ item }">
        <a v-if="item.homepage_url" :href="item.homepage_url" target="blank">{{ item.homepage_url }}</a>
      </template>
      <template v-slot:item.mon="{ item }">
        <a v-if="item.mon && item.tg_voll === '0'" :href="item.sportstaette_url_mon" target="blank">{{ item.mon }}</a>
        <a v-else :href="item.sportstaette_url_mon" target="blank" class="text-decoration-line-through">{{
          item.mon
        }}</a>
      </template>
      <template v-slot:item.tue="{ item }">
        <a v-if="item.tue && item.tg_voll === '0'" :href="item.sportstaette_url_tue" target="blank">{{ item.tue }}</a>
        <a v-else :href="item.sportstaette_url_tue" target="blank" class="text-decoration-line-through">{{
          item.tue
        }}</a>
      </template>
      <template v-slot:item.wed="{ item }">
        <a v-if="item.wed && item.tg_voll === '0'" :href="item.sportstaette_url_wed" target="blank">{{ item.wed }}</a>
        <a v-else :href="item.sportstaette_url_wed" target="blank" class="text-decoration-line-through">{{
          item.wed
        }}</a>
      </template>
      <template v-slot:item.thu="{ item }">
        <a v-if="item.thu && item.tg_voll === '0'" :href="item.sportstaette_url_thu" target="blank">{{ item.thu }}</a>
        <a v-else :href="item.sportstaette_url_thu" target="blank" class="text-decoration-line-through">{{
          item.thu
        }}</a>
      </template>
      <template v-slot:item.fri="{ item }">
        <a v-if="item.fri && item.tg_voll === '0'" :href="item.sportstaette_url_fri" target="blank">{{ item.fri }}</a>
        <a v-else :href="item.sportstaette_url_fri" target="blank" class="text-decoration-line-through">{{
          item.fri
        }}</a>
      </template>
      <template v-slot:item.sat="{ item }">
        <a v-if="item.sat && item.tg_voll === '0'" :href="item.sportstaette_url_sat" target="blank">{{ item.sat }}</a>
        <a v-else :href="item.sportstaette_url_sat" target="blank" class="text-decoration-line-through">{{
          item.sat
        }}</a>
      </template>
      <template v-slot:item.sun="{ item }">
        <a v-if="item.sun && item.tg_voll === '0'" :href="item.sportstaette_url_sun" target="blank">{{ item.sun }}</a>
        <a v-else :href="item.sportstaette_url_sun" target="blank" class="text-decoration-line-through">{{
          item.sun
        }}</a>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'CompactViewDataTable',
  props: {
    table_data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({ search: '' }),
};
</script>
