<template>
  <v-app>
    <Navbar></Navbar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Navbar from './components/NavBar.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: { Navbar, Footer },
  data: () => ({
    links: [
      { text: 'Login', icon: 'mdi-login-variant', route: 'login' },
      { text: 'Login', icon: 'mdi-login-variant', route: 'login' },
    ],
  }),
};
</script>
