import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import de from 'vuetify/es5/locale/de';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: {
      de,
    },
    current: 'de',
  },
  theme: {
    themes: {
      light: {
        primary: '#4EA385', // #E53935
        secondary: '#F9E0B4', // #FFCDD2
        tertiary: '#595959',
        quartary: '#2B2C36',
        pentary: '#DCE0E3',
        success: '#4EA385',
        accent: colors.orange.darken1, // #3F51B5
      },
      dark: {
        primary: colors.green.darken1, // #E53935
        secondary: colors.purple.darken1, // #FFCDD2
        accent: colors.orange.darken1, // #3F51B5
      },
    },
  },
});
