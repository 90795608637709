<template>
  <v-hover v-slot:default="{ hover }">
    <v-card class="mx-auto" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" :color="BGColor">
      <v-sheet color="primary" :height="SheetHeightOnMobile"
        ><v-card-title v-if="!IsMobileView" class="white--text">
          {{ data.verein_name }}
        </v-card-title>
        <v-card-title v-if="IsMobileView" class="white--text"
          ><h5>{{ data.verein_name }}</h5></v-card-title
        >
      </v-sheet>
      <v-row>
        <v-col cols="8"
          ><v-card-subtitle
            ><h3 class="headline">
              {{ data.sportart_name }}
            </h3>
          </v-card-subtitle></v-col
        >
        <v-col cols="4"
          ><div class="d-flex flex-grow-1 justify-end mr-4">
            <v-img :src="data.sportart_img" height="60" max-width="60"></v-img></div
        ></v-col>
      </v-row>
      <v-card-text>
        <v-row>
          <v-col cols="10"
            ><h3 v-if="!IsMobileView" class="headline mb-2">
              {{ data.mannschaft_name }}
            </h3>
            <h3 v-if="IsMobileView" class="mb-2">
              {{ data.mannschaft_name }}
            </h3>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-icon size="36" v-if="data.geschlecht === 'w'">mdi-gender-female</v-icon>
            <v-icon size="36" v-if="data.geschlecht === 'm'">mdi-gender-male</v-icon>
            <v-icon size="36" v-if="data.geschlecht === 'b'">mdi-gender-male-female</v-icon>
          </v-col>
        </v-row>
        <v-row v-if="data.tg_voll === '1'">
          <v-col cols="10"
            ><h3 v-if="!IsMobileView" class="headline mb-2 red--text font-weight-bold">
              Kurs belegt/nicht verfügbar
            </h3>
            <h3 v-if="IsMobileView" class="mb-2 red--text font-weight-bold">
              Kurs belegt/nicht verfügbar
            </h3>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-icon size="36" color="red">mdi-alert-circle-outline</v-icon>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="show_details = !show_details">
          Details
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn icon @click="show_details = !show_details">
          <v-icon>{{ show_details ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="show_details">
          <v-scroll-y-transition mode="out-in">
            <v-card class="mx-auto" flat max-width="100%" :color="BGColor">
              <v-card-text>
                <v-divider></v-divider>
                <v-row>
                  <v-col cols="12"> {{ data.city }} ({{ data.stadtteil }}) </v-col>
                </v-row>
                <v-row v-if="data.trainer_name && data.tg_voll === '0'">
                  <v-col tag="strong" cols="12"> Trainer/in: {{ data.trainer_name }} </v-col>
                </v-row>
                <v-row v-if="data.trainer_mail && data.tg_voll === '0'">
                  <v-col cols="12">E-Mail: {{ data.trainer_mail }}</v-col>
                </v-row>
                <v-row v-if="data.trainer_phone && data.tg_voll === '0'">
                  <v-col cols="12">Telefon: {{ data.trainer_phone }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">Jugendsport: {{ data.is_jugendsport }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">Teilnahme an MARLeFIT: {{ data.is_marlefit }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">Leistungssport: {{ data.is_leistungssport }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">Breitensport: {{ data.is_hobby }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">Altersgruppe: {{ data.alter_von }} - {{ data.alter_bis }} Jahre</v-col>
                </v-row>
                <v-divider></v-divider>

                <v-row>
                  <v-col tag="strong" cols="12">
                    Trainingszeiten:
                  </v-col>
                </v-row>
                <v-row v-if="data.mon">
                  <v-col tag="strong" cols="4">
                    Montags:
                  </v-col>
                  <v-col cols="4" class="text-center">{{ data.mon }}</v-col>
                  <v-col cols="4" class="text-right">
                    <a :href="data.sportstaette_url_mon" target="blank">{{ data.sportstaette_name_mon }} </a>
                  </v-col>
                </v-row>
                <v-row v-if="data.tue">
                  <v-col tag="strong" cols="4">
                    Dienstags:
                  </v-col>
                  <v-col cols="4" class="text-center">{{ data.tue }}</v-col>
                  <v-col cols="4" class="text-right">
                    <a :href="data.sportstaette_url_tue" target="blank">{{ data.sportstaette_name_tue }} </a>
                  </v-col>
                </v-row>
                <v-row v-if="data.wed">
                  <v-col tag="strong" cols="4">
                    Mittwochs:
                  </v-col>
                  <v-col cols="4" class="text-center">{{ data.wed }}</v-col>
                  <v-col cols="4" class="text-right">
                    <a :href="data.sportstaette_url_wed" target="blank">{{ data.sportstaette_name_wed }} </a>
                  </v-col>
                </v-row>
                <v-row v-if="data.thu">
                  <v-col tag="strong" cols="4">
                    Donnerstags:
                  </v-col>
                  <v-col cols="4" class="text-center">{{ data.thu }}</v-col>
                  <v-col cols="4" class="text-right">
                    <a :href="data.sportstaette_url_thu" target="blank">{{ data.sportstaette_name_thu }} </a>
                  </v-col>
                </v-row>
                <v-row v-if="data.fri">
                  <v-col tag="strong" cols="4">
                    Freitags:
                  </v-col>
                  <v-col cols="4" class="text-center">{{ data.fri }}</v-col>
                  <v-col cols="4" class="text-right">
                    <a :href="data.sportstaette_url_fri" target="blank">{{ data.sportstaette_name_fri }} </a>
                  </v-col>
                </v-row>
                <v-row v-if="data.sat">
                  <v-col tag="strong" cols="4">
                    Samstags:
                  </v-col>
                  <v-col cols="4" class="text-center">{{ data.sat }}</v-col>
                  <v-col cols="4" class="text-right">
                    <a :href="data.sportstaette_url_sat" target="blank">{{ data.sportstaette_name_sat }} </a>
                  </v-col>
                </v-row>
                <v-row v-if="data.sun">
                  <v-col tag="strong" cols="4">
                    Sonntags:
                  </v-col>
                  <v-col cols="4" class="text-center">{{ data.sun }}</v-col>
                  <v-col cols="4" class="text-right">
                    <a :href="data.sportstaette_url_sun" target="blank">{{ data.sportstaette_name_sun }} </a>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col tag="strong" cols="12">
                    Trainingsinhalte:
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    {{ data.training_description }}
                  </v-col>
                </v-row>
                <v-row v-if="data.mail">
                  <v-col cols="12"><a :href="`mailto:${data.mail}`">Verein kontaktieren</a></v-col>
                </v-row>
                <v-row v-if="data.phone">
                  <v-col cols="12">Telefon: {{ data.phone }}</v-col>
                </v-row>
                <v-row v-if="data.homepage_url">
                  <v-col cols="12"
                    ><a :href="data.homepage_url" target="blank">{{ data.homepage_url }}</a></v-col
                  >
                </v-row>
                <v-row v-if="data.tg_voll === '1'">
                  <v-col cols="12">
                    <p class="red--text font-weight-bold">
                      <v-icon color="red" class="mr-2">mdi-alert-circle-outline</v-icon>Achtung, Kurs belegt/nicht
                      verfügbar
                    </p></v-col
                  >
                </v-row>
              </v-card-text>
            </v-card>
          </v-scroll-y-transition>
        </div>
      </v-expand-transition>
    </v-card></v-hover
  >
</template>

<script>
import VueScreenSize from 'vue-screen-size';

export default {
  name: 'Ergebnis',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  props: {
    show_details: {
      type: Boolean,
      default: false,
    },
    data: {
      verein_name: {
        type: String,
        required: true,
      },
      phone: {
        type: String,
        required: true,
      },
      mail: {
        type: String,
        required: true,
      },
      sportart_name: {
        type: String,
        required: true,
      },
      mannschaft_name: {
        type: String,
        required: true,
      },
      geschlecht: {
        type: String,
        required: true,
      },
      is_hobby: {
        type: String,
        required: true,
      },
      is_leistungssport: {
        type: String,
        required: true,
      },
      alter_von: {
        type: String,
        required: true,
      },
      alter_bis: {
        type: String,
        required: true,
      },
      trainer_name: {
        type: String,
        required: true,
      },
      trainer_mail: {
        type: String,
        required: false,
      },
      trainer_phone: {
        type: String,
        required: false,
      },
      training_description: {
        type: String,
        required: true,
      },
      mon: {
        type: String,
        required: false,
      },
      tue: {
        type: String,
        required: false,
      },
      wed: {
        type: String,
        required: false,
      },
      thu: {
        type: String,
        required: false,
      },
      fri: {
        type: String,
        required: false,
      },
      sat: {
        type: String,
        required: false,
      },
      sun: {
        type: String,
        required: false,
      },
      sportstaette_url_mon: {
        type: String,
        required: false,
      },
      sportstaette_url_tue: {
        type: String,
        required: false,
      },
      sportstaette_url_wed: {
        type: String,
        required: false,
      },
      sportstaette_url_thu: {
        type: String,
        required: false,
      },
      sportstaette_url_fri: {
        type: String,
        required: false,
      },
      sportstaette_url_sat: {
        type: String,
        required: false,
      },
      sportstaette_url_sun: {
        type: String,
        required: false,
      },
      stadtteil: {
        type: String,
        required: false,
      },
      tg_voll: {
        type: String,
        required: false,
      },
    },
  },
  data: () => ({}),
  computed: {
    IsMobileView() {
      if (this.$vssWidth < 800) {
        return true;
      }
      return false;
    },
    SheetHeightOnMobile() {
      if (this.$vssWidth < 800) {
        return 85;
      }
      return 65;
    },
    BGColor() {
      if (this.data.tg_voll === '1') {
        return 'grey lighten-3';
      }
      return 'white';
    },
  },
};
</script>

<style></style>
