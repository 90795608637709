<template>
  <v-card>
    <v-card-title>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Suchen" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table :headers="this.table_data.headers" :items="this.table_data.items" :search="search">
      <template v-slot:item.img_url="{ item }">
        <a v-if="item.img_url" :href="item.img_url" target="blank">Vorschau</a>
      </template>
      <template v-slot:item.sportstaette_url="{ item }">
        <a v-if="item.sportstaette_url" :href="item.sportstaette_url" target="blank">{{ item.sportstaette_name }}</a>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'CompactViewDataTableVeranstaltungen',
  props: {
    table_data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({ search: '' }),
};
</script>
