var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Suchen","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":this.table_data.headers,"items":this.table_data.items,"search":_vm.search},scopedSlots:_vm._u([{key:"item.mail",fn:function(ref){
var item = ref.item;
return [(item.mail)?_c('a',{attrs:{"href":("mailto:" + (item.mail)),"target":"blank"}},[_vm._v(_vm._s(item.mail))]):_vm._e()]}},{key:"item.trainer_mail",fn:function(ref){
var item = ref.item;
return [(item.trainer_mail && item.tg_voll === '0')?_c('a',{attrs:{"href":("mailto:" + (item.trainer_mail)),"target":"blank"}},[_vm._v(_vm._s(item.trainer_mail))]):_c('a',{staticClass:"text-decoration-line-through"})]}},{key:"item.trainer_phone",fn:function(ref){
var item = ref.item;
return [(item.trainer_phone && item.tg_voll === '0')?_c('div',[_vm._v(_vm._s(item.trainer_mail))]):_c('a',{staticClass:"text-decoration-line-through"})]}},{key:"item.trainer_name",fn:function(ref){
var item = ref.item;
return [(item.trainer_name && item.tg_voll === '0')?_c('a',{attrs:{"href":("mailto:" + (item.trainer_mail)),"target":"blank"}},[_vm._v(_vm._s(item.trainer_mail))]):_c('a',{staticClass:"font-weight-bold red--text"},[_vm._v("Angebot aktuell nicht verfügbar")])]}},{key:"item.homepage_url",fn:function(ref){
var item = ref.item;
return [(item.homepage_url)?_c('a',{attrs:{"href":item.homepage_url,"target":"blank"}},[_vm._v(_vm._s(item.homepage_url))]):_vm._e()]}},{key:"item.mon",fn:function(ref){
var item = ref.item;
return [(item.mon && item.tg_voll === '0')?_c('a',{attrs:{"href":item.sportstaette_url_mon,"target":"blank"}},[_vm._v(_vm._s(item.mon))]):_c('a',{staticClass:"text-decoration-line-through",attrs:{"href":item.sportstaette_url_mon,"target":"blank"}},[_vm._v(_vm._s(item.mon))])]}},{key:"item.tue",fn:function(ref){
var item = ref.item;
return [(item.tue && item.tg_voll === '0')?_c('a',{attrs:{"href":item.sportstaette_url_tue,"target":"blank"}},[_vm._v(_vm._s(item.tue))]):_c('a',{staticClass:"text-decoration-line-through",attrs:{"href":item.sportstaette_url_tue,"target":"blank"}},[_vm._v(_vm._s(item.tue))])]}},{key:"item.wed",fn:function(ref){
var item = ref.item;
return [(item.wed && item.tg_voll === '0')?_c('a',{attrs:{"href":item.sportstaette_url_wed,"target":"blank"}},[_vm._v(_vm._s(item.wed))]):_c('a',{staticClass:"text-decoration-line-through",attrs:{"href":item.sportstaette_url_wed,"target":"blank"}},[_vm._v(_vm._s(item.wed))])]}},{key:"item.thu",fn:function(ref){
var item = ref.item;
return [(item.thu && item.tg_voll === '0')?_c('a',{attrs:{"href":item.sportstaette_url_thu,"target":"blank"}},[_vm._v(_vm._s(item.thu))]):_c('a',{staticClass:"text-decoration-line-through",attrs:{"href":item.sportstaette_url_thu,"target":"blank"}},[_vm._v(_vm._s(item.thu))])]}},{key:"item.fri",fn:function(ref){
var item = ref.item;
return [(item.fri && item.tg_voll === '0')?_c('a',{attrs:{"href":item.sportstaette_url_fri,"target":"blank"}},[_vm._v(_vm._s(item.fri))]):_c('a',{staticClass:"text-decoration-line-through",attrs:{"href":item.sportstaette_url_fri,"target":"blank"}},[_vm._v(_vm._s(item.fri))])]}},{key:"item.sat",fn:function(ref){
var item = ref.item;
return [(item.sat && item.tg_voll === '0')?_c('a',{attrs:{"href":item.sportstaette_url_sat,"target":"blank"}},[_vm._v(_vm._s(item.sat))]):_c('a',{staticClass:"text-decoration-line-through",attrs:{"href":item.sportstaette_url_sat,"target":"blank"}},[_vm._v(_vm._s(item.sat))])]}},{key:"item.sun",fn:function(ref){
var item = ref.item;
return [(item.sun && item.tg_voll === '0')?_c('a',{attrs:{"href":item.sportstaette_url_sun,"target":"blank"}},[_vm._v(_vm._s(item.sun))]):_c('a',{staticClass:"text-decoration-line-through",attrs:{"href":item.sportstaette_url_sun,"target":"blank"}},[_vm._v(_vm._s(item.sun))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }