<template>
  <v-container>
    <v-card color="primary" flat>
      <v-card-title class="text-center justify-center py-6">
        <h2 v-if="!IsMobileView" class="font-weight-bold white--text">
          {{ viewmodel.data_tables.sport_results.data.length }} Sportangebote und Veranstaltungen in Marl in
          {{ viewmodel.data_tables.vereine.data.length }} Vereinen
        </h2>
        <h2 v-if="IsMobileView" class="font-weight-bold white--text">
          {{ viewmodel.data_tables.sport_results.data.length }} Sportangebote<br />
          und<br />
          Sportveranstaltungen<br />
          in Marl in {{ viewmodel.data_tables.vereine.data.length }} Vereinen
        </h2>
      </v-card-title>
      <!-- <v-card-text class="white--text"
        ><v-row dense><v-col>Anzahl aktuelle Sportangebote: </v-col></v-row
        ><v-row dense><v-col>Anzahl registrierte Vereine: </v-col></v-row></v-card-text
      > -->
    </v-card>
    <v-tabs class="mt-6 mb-6" v-model="viewmodel.tabs.filter" color="black" show-arrows grow centered icons-and-text>
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        Sportangebote
        <v-icon>mdi-card-text-outline</v-icon>
      </v-tab>

      <v-tab href="#tab-2">
        Sportveranstaltungen
        <v-icon>mdi-calendar-month-outline</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="viewmodel.tabs.filter" touchless>
      <v-tab-item :value="'tab-' + 1">
        <v-row>
          <v-col cols="12">
            <v-card color="primary">
              <v-card-title class="secondary--text">
                Filter
              </v-card-title>
              <v-card-actions>
                <v-btn
                  text
                  class="white--text"
                  v-model="state.show_filters_sport"
                  @click="state.show_filters_sport = !state.show_filters_sport"
                  >{{ !state.show_filters_sport ? 'Anzeigen' : 'Ausblenden' }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="white"
                  v-model="state.show_filters_sport"
                  icon
                  @click="state.show_filters_sport = !state.show_filters_sport"
                >
                  <v-icon>
                    {{ state.show_filters_sport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="state.show_filters_sport">
                  <v-divider class="mt-4" dark></v-divider>

                  <v-card-text class="white--text">
                    <div class="d-flex flex-row align-center">
                      <v-switch v-model="state.show_compact_view_sport" color="white"></v-switch>Kompakte Ansicht
                    </div>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="4" xl="3"
                        ><v-autocomplete
                          label="Sportart"
                          dark
                          clearable
                          v-model="viewmodel.filter_values.sport_results.sportart"
                          :items="viewmodel.data_tables.sportarten.data"
                          item-text="sportart_name"
                          item-value="sportart_name"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="6" lg="4" xl="3"
                        ><v-autocomplete
                          label="Stadtteil"
                          dark
                          clearable
                          v-model="viewmodel.filter_values.sport_results.stadtteil"
                          :items="viewmodel.data_tables.stadtteile.data"
                          item-text="stadtteil"
                          item-value="stadtteil"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="4" xl="3"
                        ><v-autocomplete
                          label="Wochentag"
                          dark
                          auto-select-first
                          clearable
                          v-model="viewmodel.filter_values.sport_results.wochentag"
                          :items="viewmodel.filters.autocompletes.wochentage"
                        ></v-autocomplete
                      ></v-col>
                      <v-col cols="12" sm="12" md="6" lg="4" xl="3"
                        ><v-autocomplete
                          label="Verein"
                          dark
                          auto-select-first
                          clearable
                          v-model="viewmodel.filter_values.sport_results.verein"
                          :items="viewmodel.data_tables.vereine.data"
                          item-text="verein_name"
                          item-value="verein_name"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="4" xl="3"
                        ><div class="d-flex flex-row align-center">
                          <v-switch
                            color="white"
                            v-model="viewmodel.filter_values.sport_results.is_jugendsport"
                            :disabled="ShouldJugendsportBeDisabled"
                          ></v-switch>
                          nur Jugendsport anzeigen
                        </div></v-col
                      >
                      <v-col cols="12" sm="12" md="6" lg="4" xl="3"
                        ><div class="d-flex flex-row align-center">
                          <v-switch
                            color="white"
                            v-model="viewmodel.filter_values.sport_results.is_not_jugendsport"
                            :disabled="ShouldNonJugendsportBeDisabled"
                          ></v-switch>
                          nur Nicht-Jugendsport anzeigen
                        </div></v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        lg="4"
                        xl="3"
                        v-if="viewmodel.filter_values.sport_results.is_jugendsport"
                      >
                        <v-slider
                          label="von:"
                          min="0,"
                          max="18"
                          dark
                          thumb-label="always"
                          thumb-color="tertiary"
                          v-model="viewmodel.filter_values.sport_results.alter_von"
                        ></v-slider>
                        <v-slider
                          label="bis:"
                          min="0,"
                          max="18"
                          dark
                          thumb-label="always"
                          thumb-color="tertiary"
                          v-model="viewmodel.filter_values.sport_results.alter_bis"
                        ></v-slider>
                        <div class="d-flex flex-row align-center">
                          <v-switch
                            color="white"
                            v-model="viewmodel.filter_values.sport_results.is_marlefit"
                          ></v-switch>
                          Teilnahme an MARLeFIT
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="4" xl="3"
                        ><div class="d-flex flex-row align-center">
                          <v-switch
                            color="white"
                            v-model="viewmodel.filter_values.sport_results.is_breitensport"
                            :disabled="ShouldBreitensportBeDisabled"
                          ></v-switch
                          >nur Breitensport anzeigen
                        </div></v-col
                      >
                      <v-col cols="12" sm="12" md="6" lg="4" xl="3"
                        ><div class="d-flex flex-row align-center">
                          <v-switch
                            color="white"
                            v-model="viewmodel.filter_values.sport_results.is_leistungssport"
                            :disabled="ShouldLeistungssportBeDisabled"
                          ></v-switch
                          >nur Leistunggsport anzeigen
                        </div></v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="4" xl="3"
                        ><div class="d-flex flex-row align-center">
                          <v-switch color="white" v-model="viewmodel.filter_values.sport_results.tg_voll"></v-switch>Nur
                          verfügbare Angebote anzeigen
                        </div></v-col
                      >
                    </v-row>
                    <v-row
                      ><v-col cols="12" class="text-right">
                        <v-btn text color="white" @click.prevent="GetSportResults">Filter anwenden</v-btn></v-col
                      ></v-row
                    >
                    <div class="d-flex align-center"></div>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"
            ><v-card flat v-if="state.is_filtered">
              <v-row v-if="!state.show_compact_view_sport">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="4"
                  v-for="result in viewmodel.data_tables.sport_results.data"
                  :key="result.id"
                >
                  <Ergebnis :data="result"></Ergebnis>
                </v-col>
              </v-row>
              <div v-if="state.show_compact_view_sport">
                <v-row>
                  <v-col cols="12"
                    ><CompactViewDataTable :table_data="viewmodel.compact_view_data"></CompactViewDataTable
                  ></v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <div v-if="viewmodel.data_tables.sport_results.data.length === 0">
          <div class="d-flex align-self-stretch justify-center">
            <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
              <v-skeleton-loader class="mx-auto" width="300" type="card"></v-skeleton-loader>
            </v-sheet>
          </div>
          <div class="d-flex align-self-stretch justify-center mt-4">
            <p>Keine Angebote gefunden</p>
          </div>
        </div>
      </v-tab-item>

      <!-- Sportveranstaltungen ------------------------------------------------------- -->

      <v-tab-item :value="'tab-' + 2">
        <v-row>
          <v-col cols="12">
            <v-card color="primary">
              <v-card-title class="secondary--text">
                Filter
              </v-card-title>
              <v-card-actions>
                <v-btn
                  text
                  class="white--text"
                  v-model="state.show_filters_veranstaltungen"
                  @click="state.show_filters_veranstaltungen = !state.show_filters_veranstaltungen"
                  >{{ !state.show_filters_veranstaltungen ? 'Anzeigen' : 'Ausblenden' }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="white"
                  v-model="state.show_filters_veranstaltungen"
                  icon
                  @click="state.show_filters_veranstaltungen = !state.show_filters_veranstaltungen"
                >
                  <v-icon>
                    {{ state.show_filters_veranstaltungen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="state.show_filters_veranstaltungen">
                  <v-divider class="mt-4" dark></v-divider>

                  <v-card-text class="white--text">
                    <div class="d-flex flex-row align-center">
                      <v-switch v-model="state.show_compact_view_veranstaltungen" color="white"></v-switch>Kompakte
                      Ansicht
                    </div>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="4" xl="3"
                        ><v-autocomplete
                          label="Verein"
                          dark
                          auto-select-first
                          clearable
                          v-model="viewmodel.filter_values.veranstaltungen_results.verein_name"
                          :items="viewmodel.data_tables.vereine.data"
                          item-text="verein_name"
                          item-value="verein_name"
                        ></v-autocomplete
                      ></v-col>
                    </v-row>

                    <v-row
                      ><v-col cols="12" class="text-right">
                        <v-btn text color="white" @click.prevent="GetVeranstaltungen">Filter anwenden</v-btn></v-col
                      ></v-row
                    >
                    <div class="d-flex align-center"></div>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"
            ><v-card flat v-if="state.is_filtered">
              <v-row v-if="!state.show_compact_view_veranstaltungen">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="4"
                  v-for="result in viewmodel.data_tables.veranstaltungen.data"
                  :key="result.id"
                >
                  <veranstaltung :data="result"></veranstaltung>
                </v-col>
              </v-row>
              <div v-if="state.show_compact_view_veranstaltungen">
                <v-row>
                  <v-col cols="12"
                    ><CompactViewDataTableVeranstaltungen
                      :table_data="viewmodel.compact_view_data_veranstaltungen"
                    ></CompactViewDataTableVeranstaltungen
                  ></v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <div v-if="viewmodel.data_tables.veranstaltungen.data.length === 0 && !state.show_compact_view_veranstaltungen">
          <div class="d-flex align-self-stretch justify-center">
            <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
              <v-skeleton-loader class="mx-auto" width="300" type="card"></v-skeleton-loader>
            </v-sheet>
          </div>
          <div class="d-flex align-self-stretch justify-center mt-4">
            <p>Keine Veranstaltungen gefunden</p>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>

    <div v-if="!state.is_filtered">
      <div class="d-flex align-self-stretch justify-center">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader class="mx-auto" width="300" type="card"></v-skeleton-loader>
        </v-sheet>
      </div>
      <div class="d-flex align-self-stretch justify-center mt-4">
        <p>Ergebnisse werden geladen</p>
      </div>
    </div>
  </v-container>
</template>

<script>
import VueScreenSize from 'vue-screen-size';
import Ergebnis from '../components/Ergebnis.vue';
import Veranstaltung from '../components/Veranstaltung.vue';
import CompactViewDataTable from '../components/CompactViewDataTable.vue';
import CompactViewDataTableVeranstaltungen from '../components/CompactViewDataTableVeranstaltungen.vue';

const axios = require('axios');

export default {
  name: 'Home',
  mixins: [VueScreenSize.VueScreenSizeMixin],
  components: {
    Ergebnis,
    CompactViewDataTable,
    Veranstaltung,
    CompactViewDataTableVeranstaltungen,
  },
  data() {
    return {
      state: {
        is_filtered: false,
        show_filters_sport: false,
        show_filters_veranstaltungen: false,
        show_compact_view_sport: false,
        show_compact_view_veranstaltungen: false,
      },
      viewmodel: {
        tabs: {
          filter: null,
        },
        filters: {
          autocompletes: {
            wochentage: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
          },
        },
        filter_values: {
          sport_results: {
            sportart: null,
            stadtteil: null,
            wochentag: null,
            verein: null,
            alter_von: 0,
            alter_bis: 18,
            is_jugendsport: false,
            is_not_jugendsport: false,
            is_marlefit: false,
            is_breitensport: false,
            is_leistungssport: false,
            tg_voll: false,
          },
          veranstaltungen_results: {
            verein_name: null,
          },
        },
        compact_view_data: {
          headers: [
            {
              text: 'Sportart',
              value: 'sportart_name',
              width: 150,
            },
            { text: 'Verein', value: 'verein_name', width: 200 },
            { text: 'Trainingsgruppe', value: 'mannschaft_name', width: 200 },
            { text: 'Montag', value: 'mon', width: 150 },
            { text: 'Dienstag', value: 'tue', width: 150 },
            { text: 'Mittwoch', value: 'wed', width: 150 },
            { text: 'Donnerstag', value: 'thu', width: 150 },
            { text: 'Freitag', value: 'fri', width: 150 },
            { text: 'Samstag', value: 'sat', width: 150 },
            { text: 'Sonntag', value: 'sun', width: 150 },
            { text: 'Trainer', value: 'trainer_name', width: 175 },
            { text: 'Trainer E-Mail', value: 'trainer_mail' },
            { text: 'Trainer Tel.', value: 'trainer_phone', width: 175 },
            { text: 'Verein E-Mail', value: 'mail' },
            { text: 'Verein Tel.', value: 'phone' },
            { text: 'Homepage', value: 'homepage_url' },
          ],
          items: [],
          search: '',
        },
        compact_view_data_veranstaltungen: {
          headers: [
            {
              text: 'Verein',
              value: 'verein_name',
              width: 150,
            },
            { text: 'Veranstaltung', value: 'title', width: 350 },
            { text: 'Abteilung', value: 'abteilung_name', width: 200 },
            { text: 'Veranstaltungsort', value: 'sportstaette_url', width: 200 },
            { text: 'Datum', value: 'event_date', width: 100 },
            { text: 'Beginn', value: 'time_start', width: 100 },
            { text: 'Ende', value: 'time_end', width: 100 },
            { text: 'Vorschau/Flyer', value: 'img_url', width: 150 },
          ],
          items: [],
          search: '',
        },
        data_tables: {
          sport_results: {
            data: [],
          },
          veranstaltungen: {
            data: [],
          },
          sportarten: {
            data: [],
          },
          stadtteile: {
            data: [],
          },
          vereine: {
            data: [],
          },
        },
      },
    };
  },
  computed: {
    ShouldNonJugendsportBeDisabled() {
      if (this.viewmodel.filter_values.sport_results.is_jugendsport) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.viewmodel.filter_values.sport_results.is_not_jugendsport = false;
        return true;
      }
      return false;
    },
    ShouldLeistungssportBeDisabled() {
      if (this.viewmodel.filter_values.sport_results.is_breitensport) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.viewmodel.filter_values.sport_results.is_leistungssport = false;
        return true;
      }
      return false;
    },
    ShouldJugendsportBeDisabled() {
      if (this.viewmodel.filter_values.sport_results.is_not_jugendsport) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.viewmodel.filter_values.sport_results.is_jugendsport = false;
        return true;
      }
      return false;
    },
    ShouldBreitensportBeDisabled() {
      if (this.viewmodel.filter_values.sport_results.is_leistungssport) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.viewmodel.filter_values.sport_results.is_breitensport = false;
        return true;
      }
      return false;
    },
    IsMobileView() {
      if (this.$vssWidth < 800) {
        return true;
      }
      return false;
    },
  },
  methods: {
    GetSportResults() {
      const data = this.viewmodel.filter_values.sport_results;
      axios.post(`${process.env.VUE_APP_APIBASEURL}/results_GetResults.php`, data).then((response) => {
        this.viewmodel.data_tables.sport_results.data = response.data.result;
        this.viewmodel.compact_view_data.items = response.data.result;
        this.FilterSportResults();
        this.state.is_filtered = true;
      });
    },
    GetSportResultsNoFiltering() {
      axios.post(`${process.env.VUE_APP_APIBASEURL}/results_GetResults.php`).then((response) => {
        this.viewmodel.data_tables.sport_results.data = response.data.result;
        this.viewmodel.compact_view_data.items = response.data.result;
      });
      this.state.is_filtered = true;
    },
    async GetVeranstaltungen() {
      const response = await axios.post(`${process.env.VUE_APP_APIBASEURL}/results_GetVeranstaltungen.php`);
      this.viewmodel.data_tables.veranstaltungen.data = response.data.result;
      this.viewmodel.compact_view_data_veranstaltungen.items = response.data.result;
      this.FilterVeranstaltungen();
    },
    async GetAllSportarten() {
      const response = await axios.post(`${process.env.VUE_APP_APIBASEURL}/results_get_all_sportarten.php`);
      this.viewmodel.data_tables.sportarten.data = response.data.result;
    },
    async GetAllStadtteile() {
      const response = await axios.post(`${process.env.VUE_APP_APIBASEURL}/stadtteile_get_all_stadtteile.php`);
      this.viewmodel.data_tables.stadtteile.data = response.data.result;
    },
    async GetAllVereine() {
      const response = await axios.post(`${process.env.VUE_APP_APIBASEURL}/visitor_get_all_vereine.php`);
      this.viewmodel.data_tables.vereine.data = response.data.result;
    },
    async GetVeranstaltungenNoFiltering() {
      const response = await axios.post(`${process.env.VUE_APP_APIBASEURL}/results_GetVeranstaltungen.php`);
      this.viewmodel.data_tables.veranstaltungen.data = response.data.result;
      this.viewmodel.compact_view_data_veranstaltungen.items = response.data.result;
    },
    FilterSportResults() {
      // Alle Ergebnisse
      const sport_results = this.viewmodel.data_tables.sport_results.data;
      let filtered_sport_results;

      // nach Sportart filtern
      if (this.viewmodel.filter_values.sport_results.sportart) {
        filtered_sport_results = sport_results.filter(
          (result) => result.sportart_name === this.viewmodel.filter_values.sport_results.sportart,
        );
        this.viewmodel.data_tables.sport_results.data = filtered_sport_results;
        this.viewmodel.compact_view_data.items = filtered_sport_results;
      }

      // nach Verein filtern
      if (this.viewmodel.filter_values.sport_results.verein) {
        filtered_sport_results = this.viewmodel.data_tables.sport_results.data.filter(
          (result) => result.verein_name === this.viewmodel.filter_values.sport_results.verein,
        );
        this.viewmodel.data_tables.sport_results.data = filtered_sport_results;
        this.viewmodel.compact_view_data.items = filtered_sport_results;
      }

      // nach Stadtteil filtern
      if (this.viewmodel.filter_values.sport_results.stadtteil) {
        filtered_sport_results = this.viewmodel.data_tables.sport_results.data.filter(
          (result) => result.stadtteil === this.viewmodel.filter_values.sport_results.stadtteil,
        );
        this.viewmodel.data_tables.sport_results.data = filtered_sport_results;
        this.viewmodel.compact_view_data.items = filtered_sport_results;
      }

      // nach Jugendsport filtern

      if (
        this.viewmodel.filter_values.sport_results.is_jugendsport &&
        this.viewmodel.filter_values.sport_results.is_marlefit
      ) {
        filtered_sport_results = this.viewmodel.data_tables.sport_results.data.filter(
          (result) =>
            result.is_jugendsport === 'Ja' &&
            result.is_marlefit === 'Ja' &&
            result.alter_von * 1 >= this.viewmodel.filter_values.sport_results.alter_von * 1 &&
            result.alter_bis * 1 <= this.viewmodel.filter_values.sport_results.alter_bis * 1,
        );
        this.viewmodel.data_tables.sport_results.data = filtered_sport_results;
        this.viewmodel.compact_view_data.items = filtered_sport_results;
      }

      if (
        this.viewmodel.filter_values.sport_results.is_jugendsport &&
        !this.viewmodel.filter_values.sport_results.is_marlefit
      ) {
        filtered_sport_results = this.viewmodel.data_tables.sport_results.data.filter(
          (result) =>
            result.is_jugendsport === 'Ja' &&
            result.is_marlefit === 'Nein' &&
            result.alter_von * 1 >= this.viewmodel.filter_values.sport_results.alter_von * 1 &&
            result.alter_bis * 1 <= this.viewmodel.filter_values.sport_results.alter_bis * 1,
        );
        this.viewmodel.data_tables.sport_results.data = filtered_sport_results;
        this.viewmodel.compact_view_data.items = filtered_sport_results;
      }

      // nach Nicht-Jugendsport filtern

      if (this.viewmodel.filter_values.sport_results.is_not_jugendsport) {
        filtered_sport_results = this.viewmodel.data_tables.sport_results.data.filter(
          (result) => result.is_jugendsport === 'Nein',
        );
        this.viewmodel.data_tables.sport_results.data = filtered_sport_results;
        this.viewmodel.compact_view_data.items = filtered_sport_results;
      }

      // nach Breitensport filtern

      if (this.viewmodel.filter_values.sport_results.is_breitensport) {
        filtered_sport_results = this.viewmodel.data_tables.sport_results.data.filter(
          (result) => result.is_hobby === 'Ja',
        );
        this.viewmodel.data_tables.sport_results.data = filtered_sport_results;
        this.viewmodel.compact_view_data.items = filtered_sport_results;
      }

      // nach Leistungssport filtern

      if (this.viewmodel.filter_values.sport_results.is_leistungssport) {
        filtered_sport_results = this.viewmodel.data_tables.sport_results.data.filter(
          (result) => result.is_leistungssport === 'Ja',
        );
        this.viewmodel.data_tables.sport_results.data = filtered_sport_results;
        this.viewmodel.compact_view_data.items = filtered_sport_results;
      }

      // nach Verfügbarkeit filtern

      if (this.viewmodel.filter_values.sport_results.tg_voll) {
        filtered_sport_results = this.viewmodel.data_tables.sport_results.data.filter(
          (result) => result.tg_voll === '0',
        );
        this.viewmodel.data_tables.sport_results.data = filtered_sport_results;
        this.viewmodel.compact_view_data.items = filtered_sport_results;
      }

      // nach Wochentag filtern

      if (this.viewmodel.filter_values.sport_results.wochentag) {
        if (this.viewmodel.filter_values.sport_results.wochentag === 'Montag') {
          filtered_sport_results = this.viewmodel.data_tables.sport_results.data.filter((result) => result.mon);
          this.viewmodel.data_tables.sport_results.data = filtered_sport_results;
          this.viewmodel.compact_view_data.items = filtered_sport_results;
        }
        if (this.viewmodel.filter_values.sport_results.wochentag === 'Dienstag') {
          filtered_sport_results = this.viewmodel.data_tables.sport_results.data.filter((result) => result.tue);
          this.viewmodel.data_tables.sport_results.data = filtered_sport_results;
          this.viewmodel.compact_view_data.items = filtered_sport_results;
        }
        if (this.viewmodel.filter_values.sport_results.wochentag === 'Mittwoch') {
          filtered_sport_results = this.viewmodel.data_tables.sport_results.data.filter((result) => result.wed);
          this.viewmodel.data_tables.sport_results.data = filtered_sport_results;
          this.viewmodel.compact_view_data.items = filtered_sport_results;
        }
        if (this.viewmodel.filter_values.sport_results.wochentag === 'Donnerstag') {
          filtered_sport_results = this.viewmodel.data_tables.sport_results.data.filter((result) => result.thu);
          this.viewmodel.data_tables.sport_results.data = filtered_sport_results;
          this.viewmodel.compact_view_data.items = filtered_sport_results;
        }
        if (this.viewmodel.filter_values.sport_results.wochentag === 'Freitag') {
          filtered_sport_results = this.viewmodel.data_tables.sport_results.data.filter((result) => result.fri);
          this.viewmodel.data_tables.sport_results.data = filtered_sport_results;
          this.viewmodel.compact_view_data.items = filtered_sport_results;
        }
        if (this.viewmodel.filter_values.sport_results.wochentag === 'Samstag') {
          filtered_sport_results = this.viewmodel.data_tables.sport_results.data.filter((result) => result.sat);
          this.viewmodel.data_tables.sport_results.data = filtered_sport_results;
          this.viewmodel.compact_view_data.items = filtered_sport_results;
        }
        if (this.viewmodel.filter_values.sport_results.wochentag === 'Sonntag') {
          filtered_sport_results = this.viewmodel.data_tables.sport_results.data.filter((result) => result.sun);
          this.viewmodel.data_tables.sport_results.data = filtered_sport_results;
          this.viewmodel.compact_view_data.items = filtered_sport_results;
        }
      }
    },
    FilterVeranstaltungen() {
      // Alle Ergebnisse
      const veranstaltungen_results = this.viewmodel.data_tables.veranstaltungen.data;
      let filtered_veranstaltungen_results;

      // nach Verein filtern
      if (this.viewmodel.filter_values.veranstaltungen_results.verein_name) {
        filtered_veranstaltungen_results = veranstaltungen_results.filter(
          (result) => result.verein_name === this.viewmodel.filter_values.veranstaltungen_results.verein_name,
        );
        this.viewmodel.data_tables.veranstaltungen.data = filtered_veranstaltungen_results;
        this.viewmodel.compact_view_data_veranstaltungen.items = filtered_veranstaltungen_results;
      }
    },
  },
  mounted() {
    this.GetAllSportarten();
    this.GetAllStadtteile();
    this.GetAllVereine();
    this.GetSportResultsNoFiltering();
    this.GetVeranstaltungenNoFiltering();
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
};
</script>

<style scoped>
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
