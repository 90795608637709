<template>
  <v-footer color="tertiary" class="mt-16">
    <div
      class="d-flex flex-column flex-sm-column flex-md-column
            flex-lg-row flex-xl-row flex-grow-1 align-self-center justify-center"
    >
      <div class="d-flex flex-column mx-12 my-4">
        <h1 class="text-uppercase primary--text">Kontakt</h1>
        <p class="secondary--text">
          Stadt Sport Verband Marl e.V.<br />Stadthaus 1B<br />Carl-Duisberg-Straße 165<br />45772 Marl<br />Tel. 0 23
          65 / 50 747 47<br />E-Mail: info@ssv-marl.de
        </p>
        <p class="secondary--text">{{ new Date().getFullYear() }} — <strong>Sport in Marl</strong></p>
      </div>
      <div class="d-flex flex-column mx-12 my-4">
        <h1 class="text-uppercase primary--text">Übersicht</h1>
        <a v-for="(link, i) in links" :key="i" class="secondary--text" style="text-decoration: none;" :href="link.url"
          >{{ link.text }}
        </a>
      </div>
      <div class="d-flex flex-column mx-12 my-4">
        <h1 class="text-uppercase primary--text">Info</h1>
        <p class="secondary--text">
          <strong>Hinweise zur geschlechtsneutralen Formulierung:</strong>
        </p>
        <p class="secondary--text">
          Aus Gründen der besseren Lesbarkeit wird im Text <br />
          nur eine Form verwendet. Bei allen personenbezogenen<br />
          Bezeichnungen nimmt die gewählte Formulierung auf alle<br />
          Geschlechter und Geschlechtsidentitäten bezug
        </p>
      </div>
      <div class="d-flex flex-column mx-12 my-4">
        <h1 class="text-uppercase primary--text">Powered by</h1>
        <p class="secondary--text">
          Stadt Sport Verband Marl e.V.<br />Stadthaus 1B<br />Carl-Duisberg-Straße 165<br />45772 Marl<br />Tel. 0 23
          65 / 50 747 47<br />E-Mail: info@ssv-marl.de
        </p>
      </div>
    </div>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    links: [
      { text: 'Home', url: 'https://www.sport-in-marl.de/' },
      { text: 'Sportarten & Vereine', url: 'https://www.sport-in-marl.de/sportarten-vereine/' },
      { text: 'Integration', url: 'https://www.sport-in-marl.de/integration/' },
      { text: 'Über uns', url: 'https://www.sport-in-marl.de/ueber-uns/' },
      { text: 'Kontakt', url: 'https://www.sport-in-marl.de/kontakt/' },
      { text: 'Blog', url: 'https://www.sport-in-marl.de/blog/' },
      { text: 'Impressum', url: 'https://www.sport-in-marl.de/impressum/' },
      { text: 'Datenschutzerklärung', url: 'https://www.sport-in-marl.de/datenschutz/' },
    ],
  }),
};
</script>

<style scoped>
.bg-transparent {
  background: none;
}
</style>
